import { connect } from 'react-redux'
import { ApplicationState } from 'store/modules/types'
import { PureComponent, createRef, ReactNode } from 'react'
import HorizontalScrollComponent from './components'

import { getDialogVisibilitySelector } from 'store/modules/dialog/selectors'

type Props = {
  showArrows: boolean
  children: ReactNode
  className: string
  classNameContainer: string
  scrolledItemDataAttr?: string
  autoScrollTimeInterval?: number
  dialogVisible: boolean
  isScrollAlways?: boolean
  'data-test'?: string
}
type HorizontalScrollState = {
  isScrolled: boolean
}

const mapState = (state: ApplicationState) => ({
  dialogVisible: getDialogVisibilitySelector(state),
})

function getScrollbarWidth() {
  return window.innerWidth - document.documentElement.clientWidth
}

class HorizontalScroll extends PureComponent<Props, HorizontalScrollState> {
  private wrapperRef = createRef<HTMLDivElement>()

  private scrollRef = createRef<HTMLDivElement>()

  private intervalId: ReturnType<typeof setTimeout> | null

  private autoScrollIntervalInstance: ReturnType<typeof setTimeout> | null = null

  static defaultProps = {
    className: '',
    classNameContainer: '',
    showArrows: true,
  }

  state = {
    isScrolled: false, // hide scrollbar if content not long enough
  }

  componentDidMount() {
    if (document.documentElement.style.setProperty) {
      document.documentElement.style.setProperty('--scroll', `${getScrollbarWidth()}px`)
    }

    this.intervalId = setInterval(this.resizeListener, 1000)

    setTimeout(() => {
      this.initAutoScroll()
    }, 2000)
  }

  componentWillUnmount() {
    clearTimeout(this.intervalId)
    clearInterval(this.autoScrollIntervalInstance)
  }

  resizeListener = () => {
    const container = this.scrollRef.current

    if (container && container.children) {
      // console.log('container.children', )
      const childs = Array.from(container.children) || []

      const contentWidth = childs.reduce((sum, i) => {
        const computedStyle = getComputedStyle(i)
        const marginLeft = parseInt(computedStyle.marginLeft, 10)
        const marginRight = parseInt(computedStyle.marginRight, 10)

        return sum + i.scrollWidth + marginLeft + marginRight
      }, 0)

      // hide scrollbar if content not long enough
      this.setState({
        isScrolled: Math.abs(container.scrollWidth - contentWidth) < 10,
      })

      this.checkArrowsVisibility()
    }
  }

  // check if 'prev' or 'next' navigation arrows should be hidden.
  // For example, initially we hide 'prev' arrow because content can't be scrolled to left.
  // when user scroll to the end of list we hide 'next' arrow
  checkArrowsVisibility = () => {
    const container = this.scrollRef.current
    const wrapper = this.wrapperRef.current

    if (!container || !wrapper) {
      return
    }

    // check 'prev' arrow
    if (container.scrollLeft === 0) {
      wrapper.classList.add('hidePrevlArrow')
    } else {
      wrapper.classList.remove('hidePrevlArrow')
    }

    // check 'next' arrow
    if (container.scrollWidth === container.scrollLeft + container.offsetWidth) {
      wrapper.classList.add('hideNextlArrow')
    } else {
      wrapper.classList.remove('hideNextlArrow')
    }
  }

  prevScroll = () => {
    this.scrollTo(this.scrollRef.current, 'left', 500)
  }

  nextScroll = () => {
    this.scrollTo(this.scrollRef.current, 'right', 500)
  }

  scrollTo = (element: HTMLElement, direction: string, distance: number) => {
    element.scrollBy({ top: 0, left: direction === 'left' ? -distance : distance, behavior: 'smooth' })
  }

  scrollItems = (items: HTMLElement[]) => {
    if (this.scrollRef.current) {
      const itemWidth = items[0].offsetWidth
      const fullWith = itemWidth * items.length
      if (
        this.scrollRef.current.scrollWidth <=
        this.scrollRef.current.scrollLeft + this.scrollRef.current.offsetWidth
      ) {
        this.scrollTo(this.scrollRef.current, 'left', fullWith)
      } else {
        this.scrollTo(this.scrollRef.current, 'right', itemWidth)
      }
    }
  }

  autoScrollHandler = () => {
    const items = document.querySelectorAll(`[${this.props.scrolledItemDataAttr}]`)
    if (items?.length) {
      if (!this.props.dialogVisible) {
        // @ts-ignore
        this.scrollItems(items)
      }
      if (this.props.isScrollAlways) {
        // @ts-ignore
        this.scrollItems(items)
      }
    }
  }

  initAutoScroll = () => {
    if (!(this.autoScrollIntervalInstance || !this.props.autoScrollTimeInterval)) {
      this.autoScrollIntervalInstance = setInterval(this.autoScrollHandler, this.props.autoScrollTimeInterval)
    }
  }

  render() {
    const { showArrows, className, classNameContainer, children } = this.props
    const { isScrolled } = this.state
    const isShownArrows = showArrows && isScrolled

    return (
      <HorizontalScrollComponent
        wrapperRef={this.wrapperRef}
        scrollRef={this.scrollRef}
        prevScroll={this.prevScroll}
        nextScroll={this.nextScroll}
        className={className}
        classNameContainer={classNameContainer}
        showArrows={isShownArrows}
        data-test={this.props?.['data-test']}
      >
        {children}
      </HorizontalScrollComponent>
    )
  }
}

export default connect(mapState)(HorizontalScroll)
