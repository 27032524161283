import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    padding: '0px 4px',
    flexBasis: `${100 / 6}%`,
    maxWidth: `${100 / 6}%`,
    cursor: 'pointer',
    position: 'relative',
    opacity: 1,
    transition: 'all 0.3s',
    transform: 'scale(0.9)',

    // tablet
    [theme.breakpoints.down('md')]: {
      flexBasis: '25%',
      maxWidth: '25%',
    },

    // mobile
    [theme.breakpoints.down('xs')]: {
      maxWidth: '50%',
      flexBasis: '50%',
    },
  },
  moreRows: {
    // mobile
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100vw / 3 - 8px)',
      flexBasis: 'calc(100vw / 3 - 8px)',
    },
  },

  rootVisible: {
    opacity: 1,
    // top: 0,
    transform: 'scale(1)',
  },

  image: {
    borderRadius: 4,
    boxShadow: theme.shadows[2],
    display: 'block',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    '-moz-user-select': 'none',
    '-webkit-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
    '-webkit-user-drag': 'none',
    'user-drag': 'none',
    '-webkit-touch-callout': 'none',
    transition: 'all 0.3s',
    textIndent: '-10000px',
    [theme.breakpoints.up('sm')]: {
      '&:hover': {
        boxShadow: '0 10px 14px rgba(0,0,0,.1), 0 5px 14px rgba(0,0,0,.1)',
      },
    },
  },
  placeholder: {
    borderRadius: 4,
  },

  loadingImg: {
    background: 'pink',
    display: 'flex',
    height: '100%',
  },

  title: {
    textAlign: 'center',
    lineHeight: 1.3,
    whiteSpace: 'break-spaces',
    textDecoration: 'none !important',
    color: theme.palette.text.primary,
    // minHeight: '2.2rem',
    margin: '8px 0 8px',
    opacity: 0.8,
    transition: 'opacity 0.25s',
  },
  picture: {
    position: 'relative',
    display: 'block',
  },
  imageContainer: {
    position: 'relative',
    paddingTop: '59.9%',
  },
  lock: {
    position: 'absolute',
    left: -10,
    bottom: -4,
    width: 24,
    height: 24,
  },
  icon: {
    fill: theme.palette.grey.A200,
    width: 24,
    height: 24,
  },
  topRightContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  topRightContainerWithRadius: {
    borderRadius: '0 4px 0 0',
    overflow: 'hidden',
  },
  tagStyle: {
    '&:before': {
      borderTopRightRadius: 4,
      borderBottomLeftRadius: 4,
      color: '#fff',
      fontSize: 12,
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      padding: '0px 8px 1px',
    },
  },
  newGameImg: {
    '&:before': {
      content: '"New"',
      background: theme.palette.defaultTheme.gradient,
    },
  },
  topGame: {
    '&:before': {
      content: '"Top"',
      background: theme.palette.goldCoinTheme.gradient,
    },
  },
  jpTag: {
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomRightRadius: 4,
    color: '#fff',
    fontSize: 12,
    justifyContent: 'center',
    alignContent: 'center',
    top: 0,
    left: 0,
    zIndex: 2,
    background: theme.palette.sweepCoinTheme.gradient,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    padding: '0px 3px 1px',
  },
}))
