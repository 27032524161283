import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginBottom: '1rem',
  },
  scrollContainer: {
    padding: '2px 0',
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    '-webkit-overflow-scrolling': 'touch',
    msOverflowStyle: '-ms-autohiding-scrollbar',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  scrollWrap: {
    position: 'relative',
    overflow: 'hidden',
    '&:hover > svg': {
      opacity: 1,
    },
    '&:hover .game-icon-prev': {
      left: 0,
    },
    '&:hover .game-icon-next': {
      right: 0,
    },
    '&.hidePrevlArrow .game-icon-prev': {
      left: '-4rem',
    },
    '&.hideNextlArrow .game-icon-next': {
      right: '-4rem',
    },
  },
  tournamentName: {
    [theme.breakpoints.down('sm')]: {
      margin: '0 1rem .4rem',
    },
    marginBottom: '.4rem',
  },
  scrollButton: {
    position: 'absolute',
    cursor: 'pointer',
    top: '35%',
    fontSize: '3rem',
    zIndex: 1,
    opacity: 0,
    transition: 'all 0.3s',
    background: 'rgba(1, 55, 90, 0.7)',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  scrollButtonPrev: {
    borderRadius: '0 50% 50% 0',
    left: '-4rem',
  },
  scrollButtonNext: {
    right: '-4rem',
    borderRadius: ' 50% 0 0 50%',
  },
  tail: {
    minWidth: 1,
  },
}))
