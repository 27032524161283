import { connect } from 'react-redux'
import LottoTileComponent from './components/LottoTile'
import { getCurrentLotteryPicksInfoByCode, isGamesLoadedSelector } from 'store/modules/games/selectors'
import { isLoggedInSelector } from 'store/modules/user/selectors'
import { CurrentLottoPick } from 'store/modules/lottoGameFlow/types'
import { LottoProduct, ProductModes } from 'store/modules/games/types'
import { Currencies } from 'store/modules/currencies/types'
import { formatJackpot } from 'utils/helpers'
import { getClosestDraw } from 'store/modules/games/utils'
import { ApplicationState } from 'store/modules/types'

export type OwnProps = {
  game: LottoProduct
  activeCurrency: Currencies
  onClick: (game: LottoProduct) => void
  fullWidth?: boolean
}
export type Props = {
  lottoPicks: CurrentLottoPick
  isLoggedIn: boolean
  isGamesLoaded: boolean
  hideLogo?: boolean
}

const mapState = (state: ApplicationState, ownProps: OwnProps) => ({
  isGamesLoaded: isGamesLoadedSelector(state),
  lottoPicks: getCurrentLotteryPicksInfoByCode(state, {
    lotto: ownProps.game,
    activeCurrency: ownProps.activeCurrency,
  }),
  isLoggedIn: isLoggedInSelector(state),
})

const LottoTile = (props: Props & OwnProps) => {
  const closestDraw = getClosestDraw(props.game)
  const jackpot = formatJackpot(props.activeCurrency, props.game.mode, closestDraw, false)
  const isDisabled = props.game.maxLines <= props.lottoPicks?.lines?.length || !closestDraw
  const isFreeMode = props.game.mode === ProductModes.FREE
  const currency = props.activeCurrency || 'GC'

  const onTileClick = (): null => {
    if (isDisabled) {
      return null
    }

    props.onClick(props.game)

    return null
  }

  return (
    <LottoTileComponent
      hideLogo={props.hideLogo}
      lottoPicks={props.lottoPicks}
      game={props.game}
      onClick={onTileClick}
      currency={currency}
      isDisabled={isDisabled}
      isFreeMode={isFreeMode}
      closestDraw={closestDraw}
      jackpot={jackpot}
      isLoggedIn={props.isLoggedIn}
      isGamesLoaded={props.isGamesLoaded}
      fullWidth={props.fullWidth}
    />
  )
}

export default connect(mapState)(LottoTile)
