import { useDispatch } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Link from 'next/link'
import ProductGameTile from 'containers/ProductGameTile'
import useStyles from './styles'
import { GameProduct } from 'store/modules/games/types'
import { startExternalGame } from 'store/modules/slotGameFlow/actions'
import { getDataFromJson } from 'src/utils/helpers'
import content from 'translates/en/common.json'
import GridWrapper from 'src/components/GridWrapper'
import HorizontalScroll from 'src/containers/HorizontalScroll'
import SvgIcon from '@material-ui/core/SvgIcon'
import { ChevronRight } from 'mdi-material-ui'

const t = getDataFromJson(content)

type Props = {
  games: Array<GameProduct>
  title: string
  Icon: typeof SvgIcon
  url?: string
  lines?: number
  count?: number
}

export default function SlotsRow(props: Props) {
  const { Icon } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const startGame = (game: GameProduct) => {
    dispatch(startExternalGame(game))
  }

  if (props.games?.length) {
    return (
      <>
        <div className={classes.header}>
          <Typography variant='h6' component='h3' className={classes.titleWithLink} data-test={`title-${props.title}`}>
            <Icon className={classes.icon} />
            {t(props.title)}
          </Typography>

          {props.url && (
            <Link href={props.url} prefetch={false}>
              <Typography variant='body2' className={classes.link} data-test={`show-all-${props.title}`}>
                {t('Show all')} {props.count && ` (${props.count}) `} <ChevronRight className={classes.linkArrow} />
              </Typography>
            </Link>
          )}
        </div>

        <HorizontalScroll data-test={`${props.title}-games-row`}>
          <GridWrapper lines={props.lines}>
            {props.games.map((i) => {
              return (
                <ProductGameTile
                  className={classes.slotTile}
                  game={i}
                  startGameFlow={startGame}
                  moreRows
                  key={i.code}
                />
              )
            })}
          </GridWrapper>
        </HorizontalScroll>
      </>
    )
  }

  return null
}
