import useStyles from './styles'

import { freeLottoAllowedSelector } from 'store/modules/games/selectors'
import { useSelector } from 'react-redux'
import Markdown from 'markdown-to-jsx'

import OnlContent from './content/onl.md'
import PulszContent from './content/pulsz.md'
import FreeLottoContent from './content/free-lotto.md'

export default function SeoContent(props: any) {
  const classes = useStyles(props)
  const freeLottoAllowed = useSelector(freeLottoAllowedSelector)

  if (process.env.BRAND_NAME === 'onl') {
    return (
      <div className={classes.root} data-test='home-footer-seo-text'>
        <Markdown>{OnlContent}</Markdown>

        {freeLottoAllowed && <Markdown>{FreeLottoContent}</Markdown>}
      </div>
    )
  } else if (process.env.BRAND_NAME === 'pulsz') {
    return (
      <div className={classes.root} data-test='home-footer-seo-text'>
        <Markdown>{PulszContent}</Markdown>
      </div>
    )
  }

  return <div className={classes.root}>no seo text</div>
}
