import { makeStyles, Theme } from '@material-ui/core/styles'
import { calculateComponentsWidth } from 'utils/helpers'
import config from 'config/layoutConstants.json'

const styles = makeStyles((theme: Theme) => ({
  root: {
    cursor: 'pointer',
    width: calculateComponentsWidth(config.desktopMenuWidth, 12, 3, theme),
    padding: `0 ${theme.spacing(1) / 2}px`,
    border: 'none',

    // card breakpoint
    [theme.breakpoints.up('md')]: {
      width: calculateComponentsWidth(config.desktopMenuWidth, 12, 6, theme),
    },
    [theme.breakpoints.down('md')]: {
      width: calculateComponentsWidth(config.desktopMenuWidth, 8, 8, theme),
    },
    [theme.breakpoints.down('sm')]: {
      width: calculateComponentsWidth(config.desktopMenuWidth, 4, 4, theme),
      '@media (orientation : landscape)': {
        width: calculateComponentsWidth(config.desktopMenuWidth, 4, 2, theme),
      },
    },
    [theme.breakpoints.down('xs')]: {
      width: calculateComponentsWidth(0, 4, 4, theme),
      '@media (orientation : landscape)': {
        width: calculateComponentsWidth(0, 4, 2, theme),
      },
    },
  },
}))

export default styles
