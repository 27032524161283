import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    currencyName: {
      lineHeight: 1,
      marginTop: 4,
    },

    jackpot: {
      fontWeight: 'bold',
      fontSize: '1.5rem',
      // mobile
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.35rem',
        marginTop: 7,
      },
    },
  }
})
