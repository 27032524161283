import Button from 'components/Button'

import { Currencies } from 'store/modules/currencies/types'
import content from 'translates/en/common.json'
import useStyles from './styles'
import { LottoLines } from 'store/modules/lottoGameFlow/types'
import { ProductModes } from 'store/modules/games/types'
import { getDataFromJson } from 'src/utils/helpers'

type Props = {
  onClick?: () => void
  currency: Currencies
  lottoCode: string
  maxLines: number
  lottoPicks: LottoLines
  isLoggedIn: boolean
  disabled: boolean
  gameMode: ProductModes
}

const t = getDataFromJson(content)

function LottoBidButton(props: Props) {
  const { isLoggedIn, gameMode } = props
  const classes = useStyles(props)

  const isFreeMode = gameMode === ProductModes.FREE
  const title = isFreeMode ? t('free entry') : t('play now')
  const themeColor = isFreeMode ? 'SC' : props.currency
  const lottoPicks = props.lottoPicks?.lines?.length || 0
  const sendedLines = lottoPicks > props.maxLines ? props.maxLines : lottoPicks

  return (
    <Button onClick={props?.onClick} color={themeColor} className={classes.button} disabled={props.disabled}>
      <div className={classes.buttonContent}>
        {title}!
        {isLoggedIn && (
          <span className={classes.lines}>
            {sendedLines}/{props.maxLines} {t('lines')}
          </span>
        )}
      </div>
    </Button>
  )
}

export default LottoBidButton
