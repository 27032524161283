import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    padding: '16px 0',
  },
  title: {
    padding: '0 16px',
    margin: '16px 0',
  },

  titleWithLink: {
    padding: '0 16px',
    margin: '16px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  link: {
    fontSize: '1rem',
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  image: {
    width: '100%',
    borderRadius: 4,
    transition: 'all 0.3s',
    '@media (min-width:450px)': {
      '&:hover': {
        boxShadow: '0 10px 14px rgba(0,0,0,.1), 0 5px 14px rgba(0,0,0,.1)',
      },
    },
  },
}))
