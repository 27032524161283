import useStyles from './styles'
import Typography from '@material-ui/core/Typography'

type Props = {
  jackpot: string | number
  currencyTitle: string
}
export default function JackpotDescription(props: Props) {
  const classes = useStyles(props)

  return (
    <div className={classes.root}>
      <Typography variant='h6' className={classes.jackpot}>
        {props.jackpot}
      </Typography>
      <Typography variant='caption' className={classes.currencyName}>
        {props.currencyTitle}
      </Typography>
    </div>
  )
}
