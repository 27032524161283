import BannerTile from 'components/BannerTile'
import { OpenDialogAction, DialogNames } from 'store/modules/dialog/types'
import { ApplicationState } from 'store/modules/types'
import useStyles from '../styles'
import { sweepstakeEnabledSelector } from 'src/store/modules/appConfig/selectors'
import { connect } from 'react-redux'
import Img from 'components/Img'

type Props = {
  openDialog: (modalName: DialogNames) => OpenDialogAction
  isLoggedIn: boolean
  sweepstakeEnabled: boolean
}

const mapState = (state: ApplicationState) => ({
  sweepstakeEnabled: sweepstakeEnabledSelector(state),
})

function HomeBanners(props: Props) {
  const classes = useStyles(props)

  const onInviteFriendsClick = () => {
    props.openDialog('INVITE_FRIENDS_DIALOG')
  }

  if (!process.env.INVITE_FRIENDS_MOBILE) {
    return null
  }
  if (!props.isLoggedIn || !props.sweepstakeEnabled) {
    return (
      <BannerTile onClick={onInviteFriendsClick} dataTest='finvite-friends-banner'>
        <picture>
          <Img
            src={`${process.env.IMG_ORIGIN}/banner/invite-friends_${process.env.BRAND_NAME}.png`}
            className={classes.image}
            alt='invite friends'
          />
        </picture>
      </BannerTile>
    )
  }

  return (
    <BannerTile onClick={onInviteFriendsClick} dataTest='invite-friends-banner'>
      <picture>
        <img
          src={`${process.env.IMG_ORIGIN}/banner/refer-a-friend-hp-banner.jpg`}
          className={classes.image}
          alt='invite friends'
        />
      </picture>
    </BannerTile>
  )
}

export default connect(mapState)(HomeBanners)
