import { makeStyles, Theme } from '@material-ui/core/styles'
import { calculateComponentsWidth } from 'src/utils/helpers'
import config from 'config/layoutConstants.json'

export default makeStyles((theme: Theme) => ({
  icon: {
    opacity: 0.7,
    height: '1.3rem',
    marginRight: '0.3rem',
  },

  title: {
    padding: '0 16px',
    margin: '16px 0',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '16px 0',
  },
  titleWithLink: {
    padding: '0 16px',
    display: 'flex',
  },
  link: {
    textTransform: 'uppercase',
    display: 'flex',
    fontSize: '0.8rem',
    fontWeight: 500,
    textDecoration: 'none',
    color: theme.palette.primary.main,
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0px 14px',
    userSelect: 'none',
  },

  linkArrow: {
    marginBottom: 2,
    fontSize: '1rem',
  },

  slotTile: {
    width: calculateComponentsWidth(config.desktopMenuWidth, 12, 2, theme),
    [theme.breakpoints.down('lg')]: {
      width: calculateComponentsWidth(config.desktopMenuWidth, 12, 2, theme),
    },
    [theme.breakpoints.down('md')]: {
      width: calculateComponentsWidth(config.desktopMenuWidth, 8, 2, theme),
    },
    [theme.breakpoints.down('sm')]: {
      width: calculateComponentsWidth(config.desktopMenuWidth, 4, 1, theme),
    },
    [theme.breakpoints.down('xs')]: {
      width: calculateComponentsWidth(0, 4, 1, theme),
      '& .MuiTypography-root': {
        display: 'none',
      },
    },
    '@media(max-width: 550px)': {
      width: calculateComponentsWidth(0, 4, 2, theme),
    },
  },
}))
