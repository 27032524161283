import BannerTile from 'components/BannerTile'

import useStyles from '../styles'
import useMediaQuery from 'hooks/useMediaQuery'
import { connect } from 'react-redux'
import { openShopHandler } from 'src/store/modules/shop/actions'
import Img from 'components/Img'

type Props = {
  openShopHandler: typeof openShopHandler
}

const mapDispatch = {
  openShopHandler,
}

function FirstTimeGCOffers(props: Props) {
  const classes = useStyles(props)
  const isMobile = useMediaQuery('xs')

  const onOfferBannerClick = () => {
    props.openShopHandler(isMobile)
  }

  return (
    <BannerTile onClick={onOfferBannerClick} dataTest='first-time-gc-offer-banner'>
      <picture>
        <Img
          src={`${process.env.IMG_ORIGIN}/banner/1st-purchase-offer.svg`}
          className={classes.image}
          alt='shop banner'
        />
      </picture>
    </BannerTile>
  )
}

export default connect(null, mapDispatch)(FirstTimeGCOffers)
