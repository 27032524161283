import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import AspectRatioBox from 'components/AspectRatioBox'
import LottoBidButton from 'components/LottoBidButton'

import { LottoProduct, DrawItem, ProductModes } from 'store/modules/games/types'
import { Currencies } from 'store/modules/currencies/types'
import { CurrentLottoPick } from 'store/modules/lottoGameFlow/types'

import { formatCountdownTimer } from 'utils/date'
import t from 'translates/en/common.json'
import useStyles from './styles'
import JackpotDescription from 'src/components/JackpotDescription'

export type Props = {
  game: LottoProduct
  fullWidth?: boolean
  lottoPicks: CurrentLottoPick
  isLoggedIn: boolean
  currency: Currencies
  onClick: () => void
  isDisabled: boolean
  isFreeMode: boolean
  closestDraw: DrawItem
  jackpot: string | number
  isGamesLoaded: boolean
  hideLogo?: boolean
}

const LottoTile = (props: Props) => {
  const classes = useStyles(props)
  const currencyTitle = props.isFreeMode ? ProductModes.FREE : props.currency

  return (
    <div className={props.fullWidth ? classes.fullRoot : classes.root}>
      <AspectRatioBox ratio='16:9'>
        <Paper className={classes.smallRoot} onClick={props.onClick}>
          <div className={classes.head}>
            {!props.hideLogo && (
              <img
                src={`${process.env.IMG_ORIGIN}/logos/lt-v2-${props.game.code}-${props.currency}.svg`}
                className={classes.image}
                alt={props.game.code}
              />
            )}

            <Typography variant='body2' className={classes.time}>
              {formatCountdownTimer(props.closestDraw?.closingDate)}
            </Typography>
          </div>
          <JackpotDescription jackpot={props.jackpot} currencyTitle={t[currencyTitle]} />

          <LottoBidButton
            gameMode={props.game.mode}
            maxLines={props.game.maxLines}
            lottoPicks={props.lottoPicks}
            isLoggedIn={props.isLoggedIn}
            currency={props.currency}
            lottoCode={props.game.code}
            disabled={props.isDisabled}
          />
        </Paper>
      </AspectRatioBox>
    </div>
  )
}

export default LottoTile
