import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  icon: {
    fill: theme.palette.grey.A200,
    width: 24,
    height: 24,
  },
  moreInfoBtn: {
    backgroundColor: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,
    opacity: '0.6',
  },
  moreInfoBtnIcon: {
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
