import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    padding: `0 ${theme.spacing(4)}px`,
    flexGrow: 1,
  },
  row: {
    margin: `0 -${theme.spacing(2) + theme.spacing(1) / 2}px 16px`,
    display: 'flex',
    flexWrap: 'wrap',
  },
  line: {
    whiteSpace: 'nowrap',
    display: 'flex',
    width: '100%',

    [theme.breakpoints.down('xs')]: {
      marginBottom: '8px',
      '&:nth-last-of-type(1)': {
        marginBottom: 0,
      },
    },
  },
}))
