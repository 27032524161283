import { makeStyles, Theme } from '@material-ui/core/styles'
import config from 'config/layoutConstants.json'
import { calculateComponentsWidth } from 'utils/helpers'

export default makeStyles((theme: Theme) => {
  return {
    root: {
      position: 'relative',
      width: calculateComponentsWidth(config.desktopMenuWidth, 12, 3, theme),
      padding: `0px ${theme.spacing(1) / 2}px`,
      '@media(min-width: 1550px)': {
        width: calculateComponentsWidth(config.desktopMenuWidth, 12, 2, theme),
      },
      [theme.breakpoints.down('md')]: {
        width: calculateComponentsWidth(config.desktopMenuWidth, 8, 3, theme),
      },
      [`${theme.breakpoints.down('xs')} and (orientation: portrait)`]: {
        width: calculateComponentsWidth(0, 4, 3, theme),
      },
      [`${theme.breakpoints.down('xs')} and (orientation: landscape)`]: {
        width: calculateComponentsWidth(0, 8, 3, theme),
      },
    },

    head: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '8px 8px 0',
    },
    fullRoot: {
      width: '100%',
      height: '100%',
    },
    smallRoot: {
      cursor: 'pointer',
      width: '100%',
      alignItems: 'center',
      display: 'flex',
      position: 'relative',
      justifyContent: 'space-between',
      // @ts-ignore
      background: theme.palette.primary.background,
      flexDirection: 'column',
      // margin: `1rem ${tileHorizontalMargin}`,
      // marginTop: '0',
      // marginRight: '0',
      // desktop
      [theme.breakpoints.up('sm')]: {
        '&:hover': {
          boxShadow: '0 10px 14px rgba(0,0,0,.1), 0 5px 14px rgba(0,0,0,.1)',
        },
      },
    },

    time: {
      opacity: 0.6,
      whiteSpace: 'nowrap',
    },

    image: {
      alignSelf: 'flex-start',
      width: 60,
    },
    lock: {
      position: 'absolute',
      left: '-10px',
      bottom: '-10%',
    },
    icon: {
      fill: theme.palette.text.primary,
      width: 24,
      height: 24,
    },
  }
})
