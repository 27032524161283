import { SyntheticEvent, ReactNode } from 'react'

import useStyles from './styles'

type Props = {
  onClick?: (e: SyntheticEvent) => void
  children: ReactNode
  dataTest: string
}

function BannerTile(props: Props) {
  const classes = useStyles(props)

  return (
    <div data-banner className={classes.root} onClick={props?.onClick} role='button' data-test={props.dataTest}>
      {props.children}
    </div>
  )
}

export default BannerTile
