import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  root: {
    padding: '16px',
    textAlign: 'center',
    '& h1': {
      fontSize: '1.25rem',
      opacity: '0.5',
      fontWeight: 500,
    },
    '& p': {
      fontSize: '0.875rem',
      lineHeight: 1.4,
      opacity: '0.5',
    },
  },
  text: {},
})
