import { ReactNode, Fragment } from 'react'
import useStyles from './styles'
import cx from 'classnames'

type DoubleBoxType = {
  children: Array<ReactNode>
  rootClass?: string
  lines?: number
}

const getChildrenPerLines = (children: ReactNode[], lines: number) => {
  const itemsPerLine = Math.ceil(children.length / lines)
  let start = 0
  let end = itemsPerLine
  const obj = new Array(lines).fill(' ').reduce((acc, _: never, index) => {
    acc[index] = children
      .filter(Boolean)
      .filter((el, idx) => {
        if (idx >= start && idx < end) {
          return el
        }

        return null
      })
      .filter(Boolean)
    start = end
    end += itemsPerLine

    return acc
  }, {})

  return obj
}

export default function GridWrapper(props: DoubleBoxType) {
  const classes = useStyles(props)
  const games = getChildrenPerLines(props.children, props.lines)

  return (
    <div className={cx([classes.root, props.rootClass])}>
      <div className={classes.row}>
        {Object.keys(games).map((line) => {
          return (
            <div className={classes.line} key={line}>
              {games[line].map((el: any, index: number) => (
                <Fragment key={el.key || index}>{el}</Fragment>
              ))}
            </div>
          )
        })}
      </div>
    </div>
  )
}

GridWrapper.defaultProps = {
  lines: 1,
}
