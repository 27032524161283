import { useSelector } from 'react-redux'
import { isAppInitializedSelector } from 'store/modules/appConfig/selectors'
import ProductTile from 'components/ProductTile'
import { GameProduct } from 'store/modules/games/types'
import { getActiveCurrencyIdSelector } from 'store/modules/currencies/selectors'

type Props = {
  game: GameProduct
  startGameFlow: Function
  onClick?: () => void
  className?: string
  moreRows?: boolean
}

export default function ProductGameTile(props: Props) {
  const isAppInitialized = useSelector(isAppInitializedSelector)
  const activeCurrency = useSelector(getActiveCurrencyIdSelector)

  const onTileClick = (): null => {
    if (!isAppInitialized) {
      return null
    }

    props.startGameFlow(props.game)

    return null
  }

  return (
    <ProductTile
      className={props.className}
      role='link'
      onClick={onTileClick}
      game={props.game}
      moreRows={props.moreRows}
      activeCurrency={activeCurrency}
    />
  )
}
