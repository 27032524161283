import HorizontalScroll from 'containers/HorizontalScroll'
import LottoTile from 'src/containers/LottoTile'
import GridWrapper from 'components/GridWrapper'
import { LottoProduct } from 'store/modules/games/types'
import { Currencies } from 'store/modules/currencies/types'

type Props = {
  visibleAndSortedLottos: Array<LottoProduct>
  startGameFlow: (game: LottoProduct) => void
  activeCurrency?: Currencies
}

function LottoItems(props: Props) {
  return (
    <div data-id='lotto'>
      <HorizontalScroll>
        <GridWrapper>
          {props.visibleAndSortedLottos.map((i) => {
            return (
              <LottoTile
                game={i}
                activeCurrency={props.activeCurrency || 'GC'}
                onClick={props.startGameFlow}
                key={i.code}
              />
            )
          })}
        </GridWrapper>
      </HorizontalScroll>
    </div>
  )
}

export default LottoItems
