import useStyles from './styles'
import { useSelector } from 'react-redux'

import { InformationOutline } from 'mdi-material-ui'
import Link from 'next/link'

import { GameProduct, GameTypes } from 'store/modules/games/types'
import { Currencies } from 'store/modules/currencies/types'
import { convertTitleToUrl } from 'src/utils/url'
import { isLoggedInSelector } from 'src/store/modules/user/selectors'

type Props = {
  game: GameProduct
  activeCurrency: Currencies
  className?: string
}

const MoreInfoBtn = (props: Props) => {
  const classes = useStyles(props)
  const loggedIn = useSelector(isLoggedInSelector)

  const getCurrentStartUrlByType: { [key in GameTypes]?: string } = {
    slots: 'slots',
    table: 'table-games',
    video_slots: 'slots',
  }

  const url = `/games/${getCurrentStartUrlByType[props.game.type]}/${convertTitleToUrl(props.game.title)}/${
    props.activeCurrency === 'SC' ? 'sweepstake' : ''
  }`

  return (
    !loggedIn && (
      <div className={classes.moreInfoBtn}>
        <Link href={url}>
          <a className={classes.moreInfoBtnIcon} onClick={(e) => e.stopPropagation()}>
            <InformationOutline />
          </a>
        </Link>
      </div>
    )
  )
}

export default MoreInfoBtn
