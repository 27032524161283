import { useInView } from 'react-intersection-observer'
import cx from 'classnames'

import Typography from '@material-ui/core/Typography'
import MoreInfoBtn from './MoreInfoBtn'

import { GameProduct } from 'store/modules/games/types'
import { Currencies } from 'store/modules/currencies/types'

import useStyles from './styles'

type Props = {
  game: GameProduct
  role?: string
  onClick?: Function
  activeCurrency: Currencies
  className?: string
  moreRows?: boolean
}

const ProductTile = (props: Props) => {
  const classes = useStyles(props)
  const topTag = props.game.tags.includes('top')
  const newTag = props.game.tags.includes('new')
  const jackpotTag = props.game.tags.includes('jackpot')
  const gameTag = (topTag && classes.topGame) || (newTag && classes.newGameImg)

  const [ref, inView] =
    process.browser && window.IntersectionObserver
      ? useInView({
          /* Optional options */
          threshold: 0,
          triggerOnce: true,
        })
      : [null, true]

  return (
    <div
      className={cx(
        classes.root,
        props.moreRows && classes.moreRows,
        props?.className,
        process.browser && inView && classes.rootVisible
      )}
      onClick={() => {
        props.onClick()
      }}
      ref={ref}
    >
      <div className={cx(classes.imageContainer)} data-test={`game-tile-${props.game.code}`}>
        {jackpotTag && <div className={classes.jpTag}>Jackpot</div>}
        <div
          className={cx(
            classes.topRightContainer,
            gameTag ? classes.tagStyle : classes.topRightContainerWithRadius,
            gameTag
          )}
        >
          {props.game.moreInfo && <MoreInfoBtn game={props.game} activeCurrency={props.activeCurrency} />}
        </div>
        <picture>
          {inView && process.browser ? (
            <>
              <source
                srcSet={`${process.env.IMG_ORIGIN}/game-tiles/${props.game.code}.webp`}
                className={classes.image}
                type='image/webp'
              />
              <img
                src={`${process.env.IMG_ORIGIN}/game-tiles/${props.game.code}.jpg`}
                className={classes.image}
                alt={props.game.title}
              />
            </>
          ) : (
            <noscript>
              <img
                className={classes.image}
                src={`${process.env.IMG_ORIGIN}/game-tiles/${props.game.code}.jpg`}
                alt={props.game.title}
                title={props.game.title}
              />
            </noscript>
          )}
        </picture>
      </div>

      <Typography variant='subtitle2' className={classes.title}>
        {props.game.title}
      </Typography>
    </div>
  )
}

export default ProductTile
