import { LegacyRef, ReactNode } from 'react'
import cx from 'classnames'
import { ChevronLeft, ChevronRight } from 'mdi-material-ui'
import useStyles from './styles'

type HorizontalScrollWebProps = {
  showArrows: boolean
  className: string
  classNameContainer: string
  wrapperRef: LegacyRef<HTMLDivElement>
  scrollRef: LegacyRef<HTMLDivElement>
  prevScroll: () => void
  nextScroll: () => void
  children: ReactNode
  'data-test'?: string
}

function HorizontalScrollWeb(props: HorizontalScrollWebProps) {
  const { wrapperRef, scrollRef, prevScroll, nextScroll, className, classNameContainer, showArrows, children } = props

  const classes = useStyles(props)

  return (
    <div className={cx(classes.scrollWrap, className)} ref={wrapperRef} data-test={props?.['data-test']}>
      {showArrows && (
        <>
          <ChevronLeft
            onClick={prevScroll}
            className={cx(classes.scrollButton, classes.scrollButtonPrev, 'game-icon-prev')}
          />
          <ChevronRight
            onClick={nextScroll}
            className={cx(classes.scrollButton, classes.scrollButtonNext, 'game-icon-next')}
          />
        </>
      )}

      <div className={cx(classes.scrollContainer, classNameContainer)} ref={scrollRef}>
        {children}

        {/* margin-right wasn't applied for last card */}
        {/* <div className={classes.tail} /> */}
      </div>
    </div>
  )
}

export default HorizontalScrollWeb
