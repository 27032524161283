import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  buttonContent: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 1.1,
  },

  button: {
    marginBottom: 8,
    minWidth: 135,
    minHeight: 38,
  },

  lines: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '0.625rem',
  },
})
