import { ReactNode } from 'react'
import cx from 'classnames'
import { Ratio } from 'src/theme/types'
import useStyles from './styles'

type AspectRatioBoxType = {
  ratio: Ratio
  children?: ReactNode
  image?: string
}

export default function AspectRatioBox(props: AspectRatioBoxType) {
  const classes = useStyles(props)
  const { ratio } = props

  return (
    <div className={cx(classes.root, classes[ratio])}>
      <div className={classes.content}>{props.children}</div>
    </div>
  )
}
