import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => {
  return {
    root: {
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      width: '100%',
      height: '100%',
      display: 'flex',
      position: 'relative',
      '&:after': {
        content: '""',
        display: 'flex',
        width: 0,
        overflow: 'hidden',
      },
    },
    '1:1': {
      '&:after': {
        paddingTop: '100%',
      },
    },
    '16:9': {
      '&:after': {
        paddingTop: '56.25%',
      },
    },
    '4:3': {
      '&:after': {
        paddingTop: '75%',
      },
    },
    '3:2': {
      '&:after': {
        paddingTop: '66.66%',
      },
    },
    '8:5': {
      '&:after': {
        paddingTop: '62.5%',
      },
    },
    '21:9': {
      '&:after': {
        paddingTop: '42.8571%',
      },
    },
    content: {
      display: 'flex',
      width: '100%',
    },
  }
})
